import type { ComponentPropsWithRef } from 'react'

/**
    Condensed version - loaded from root SVG def
*/
export function UseBuyOnLoungePair(
  props: ComponentPropsWithRef<'svg'> & { alt?: string },
) {
  const { className, alt = 'Lounges you can book today with LoungePair' } =
    props
  return (
    <svg
      className={className || ''}
      viewBox="0 0 13 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{alt}</title>
      <use href="#BuyOnLoungePair" fill="currentColor" />
    </svg>
  )
}

export function BuyOnLoungePair(
  props: ComponentPropsWithRef<'svg'> & { alt?: string },
) {
  const { className, alt = 'Lounges you can book today with LoungePair' } =
    props
  return (
    <svg
      className={className || ''}
      viewBox="0 0 13 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{alt}</title>
      <defs>
        <path
          id="BuyOnLoungePair"
          d="M6.20553 0C2.78482 0 0 2.82222 0 6.28889C0 7.28889 0.219277 8.22222 0.635903 9.06667L0.701686 9.2L6.20553 20L11.6655 9.28889L11.6875 9.22222C12.1479 8.35556 12.4111 7.35556 12.4111 6.28889C12.4111 2.82222 9.64818 0 6.20553 0ZM9.1877 7.6L6.5783 6.57778V7.51111C6.5783 7.6 6.55638 7.68889 6.51252 7.75556L7.43348 8.37778V8.88889L6.18361 8.37778L4.93373 8.88889V8.37778L5.85469 7.75556C5.81084 7.68889 5.78891 7.6 5.78891 7.51111V6.57778L3.17951 7.57778V6.95556L5.83276 4.88889V3.51111C5.83276 3.26667 5.92047 3 6.09589 2.82222C6.11782 2.77778 6.16168 2.77778 6.20553 2.77778C6.24939 2.77778 6.29324 2.8 6.31517 2.82222C6.46867 3.02222 6.5783 3.26667 6.5783 3.51111V4.88889L9.1877 6.95556V7.6Z"
        />
      </defs>
      <use href="#BuyOnLoungePair" fill="currentColor" />
    </svg>
  )
}
